
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import { parsePhoneNumberFromString, CountryCode, PhoneNumber, E164Number, CountryCallingCode, getCountryCallingCode } from "libphonenumber-js";
import Multiselect from "vue-multiselect";
import CountryFlag from "vue-country-flag";
import countries from "../assets/countries";

const countryCodeOptions = [...Object.entries(countries)]
.map(([value, text]) => ({text, value} as CountryCodeOption))
.sort((a, b) => a.text.localeCompare(b.text))
.sort(({value: a}, {value: b}) => {
	const preferred = ["CA", "US"/*, "GB", "FR", "AU", "NZ"*/].reverse();

	return (preferred.indexOf(a) * -1) - (preferred.indexOf(b) * -1)
});

interface CountryCodeOption {
	text: string;
	value: CountryCode | "";
}

@Component({
	components: {
		Multiselect,
		CountryFlag,
	},
})
export default class PhoneNumberVue extends Vue {
	/**
	 * input value in the E.164 format
	 */
	@Prop({type: String, default: ""})
	value!: E164Number;

	@Prop({type: String})
	autocomplete!: string;

	@Prop({type: String, default: ""})
	placeholder!: string;

	@Watch("value", {
		immediate: true,
	})
	onValueChange(value: E164Number | null) {
		value = value || null;

		let phoneNumber: PhoneNumber | null = null;

		if (value) {
			phoneNumber = parsePhoneNumberFromString(value as string) || null;
		}

		this.phoneNumber = phoneNumber;
	}

	/**
	 * The number's country code
	 */
	@Prop({type: String, default: null})
	countryCode!: CountryCode | null;

	@Watch("countryCode")
	onCountryCodeChange(val: CountryCode | null, _oldVal: CountryCode | null) {
		this.theCountryCode = val;
	}

	@Ref("input") readonly inputElement!: HTMLInputElement;

	/**
	 * Display value, and user input
	 */
	formattedValue: string = "";

	/**
	 * The number's country code
	 */
	theCountryCode: CountryCode | null = this.countryCode;

	get countryCodeOption(): CountryCodeOption | null {
		const {theCountryCode} = this;

		return theCountryCode && this.countryCodeOptions.find(option => option.value === theCountryCode) || null;
	}

	set countryCodeOption(input: CountryCodeOption | null) {
		this.theCountryCode = input && input.value || null;
	}

	countryCodeOptions: CountryCodeOption[] = countryCodeOptions;

	@Watch("theCountryCode")
	onTheCountryCodeChange() {
		this.inputElement.focus();
	}

	get number(): E164Number | null {
		return this.phoneNumber && this.phoneNumber.isValid() ? this.phoneNumber.number : null;
	}

	@Watch("number")
	onNumberChange(val: E164Number | null) {
		this.$emit("input", val);
	}

	get cleanValue() {
		return this.formattedValue.replace(/[\(\)\s\-\.]/g, "");
	}

	get countryCallingCode(): CountryCallingCode | "" {
		return this.theCountryCode && getCountryCallingCode(this.theCountryCode) || "";
	}

	get phoneNumber(): PhoneNumber | null {
		return parsePhoneNumberFromString(this.cleanValue, this.theCountryCode || undefined) || null;
	};

	set phoneNumber(phoneNumber: PhoneNumber | null) {
		this.onPhoneNumberChange(phoneNumber, null);
	}

	get countryCodeRegex() {
		return new RegExp(`^\\+${this.countryCallingCode || "" as string}`)
	}

	@Watch("phoneNumber")
	onPhoneNumberChange(phoneNumber: PhoneNumber | null, _oldPhoneNumber: PhoneNumber | null) {
		if (phoneNumber) {
			const formattedValue = phoneNumber.formatNational().replace(this.countryCodeRegex, "").trim();

			this.formattedValue = formattedValue;
			this.theCountryCode = phoneNumber.country || null;
		}
	}

	onEnter(event: KeyboardEvent) {
		const {number} = this;

		if (number) {
			this.$emit("enter", event, number);
		}
	}
}
